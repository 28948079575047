import { LitElement, html, css } from 'lit';
import { krumStyles } from '../styles/krum-styles.js';
import { flexStyles } from '../styles/flex-styles.js';
import '../components/krum-card-image.js';

export class KrumSectionPartnerSolutions extends LitElement {
    static get properties() {
        return {
            cards: {
                type: Array,
            },
            type: {
                type: String,
                attribute: true,
                reflect: true,
            },
        };
    }
    constructor() {
        super();
        this.cards = [];
    }
    static get styles() {
        return [
            flexStyles,
            krumStyles,
            css`
              :host {
                display: block;
                overflow-x: hidden;
                background-color: var(--krum-background-color);
                font-family: var(--krum-font-family);
                max-width: var(--krum-content-max-width);
                margin-left: auto;
                margin-right: auto;
              }

              :host([type="secondary"]) {
                background-color: var(--krum-secondary-background-color);
              }

              :host([type="action"]) {
                background-color: var(--krum-secondary-text-color);
              }

              :host([type="action"]) .section-title {
                color: var(--krum-white-text-color);
              }

              :host([type="action"]) .section-text {
                  color: var(--krum-muted-white-text-color);
              }

              h1 {
                margin-bottom: 4px;
              }

              h3 {
                color: var(--krum-color-medium-dark-gray);
                margin: 0px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
              }

              hr {
                border: 0px;
                border-top: 1px solid var(--krum-color-medium-gray);
              }



              .section {
                padding: 32px;
                padding-bottom: 120px;
                padding-top: 40px;
              }

              .section > * {
                max-width: var(--krum-content-max-width);
              }

              .section-title {
                font-size: 36px;
                font-weight: bold;
                margin-bottom: 16px;
                color: var(--krum-secondary-text-color);
              }

              .section-text {
                font-size: 24px;
                font-weight: 300;
                line-height: 1.5;
                color: var(--krum-focused-action-color);
                text-align: justify;
              }

              .card-section {
                margin-top: 80px;
              }

              .cards {
                align-items: flex-start;
                align-self: stretch;
                gap: 32px;
                margin-top: 40px;
              }

              .card-title {
                  font-size: 24px;
                  font-weight: bold;
                  color: var(--krum-secondary-text-color);
              }

              .card-text {
                  font-size: 20px;
                  font-weight: 300;
                  line-height: 1.4;
                  color: var(--krum-primary-text-color);
              }

              .card-grid {
                  display: grid;
                  grid-template-columns: repeat(auto-fill, minmax(auto, 560px));
                  max-width: unset;
                  width: 100%;
                  justify-content: center;
                  grid-column-gap: 32px;
                  grid-row-gap: 32px;
                  box-sizing: border-box;
                  padding: 0 120px 64px 120px;
              }

              .card-container {
                  min-height: 256px;
                  max-width: 560px;
                  padding: 64px 40px;
                  box-sizing: border-box;
                  border-radius: 2px;
                  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
                  background-color: #fefefe;
              }


              /**
              * Mobile considerations for font sizes and section paddings at the
              * 540px breakpoint.  This will also adjust padding for the tile image
              * and the size of the overlay title.
              */
              @media all and (max-width: 540px) {
                .section {
                  padding: 64px 32px;
                }

                .section-title {
                  font-size: 24px;
                }

                .section-text {
                  font-size: 16px;
                }

                .card-grid {
                  padding: 0 32px 64px 32px;
                }

                .card-container {
                  padding: 32px;
                  min-height: 140px;
                }

                .card-title {
                  font-size: 16px;
                }

                .card-text {
                  font-size: 14px;
                }
              }
            `,
        ];
    }
    render() {
        return html`
        <section class="section vertical layout">
            <div>
              <h1>Partner Solutions</h1>
              <span>Krum builds partnerships with specialty solutions providers and products, to provide foundational excellence. Because we are a unique combination of developer, operator, and end-user, we're able to develop meaningful partnerships. We take partnerships seriously and invest expertise into solutions that we believe in and use ourselves.</span>
            </div>
            <div class="card-section" style="margin-top: 40px">
              <h3>
                Cloud + DevOps + IoT
              </h3>
              <hr style="">
              <div class="cards horizontal layout wrap">
                  <krum-card-image style="width: 250px;" name="SUSE" imageSrc="/images/partner/partner-suse.png"></krum-card-image>
                  <krum-card-image style="width: 340px;" name="SUSE Rancher" imageSrc="/images/partner/partner-rancher.png"></krum-card-image>
                  <krum-card-image style="width: 250px;" name="SUSE Harvester" imageSrc="/images/partner/partner-harvester.png"></krum-card-image>
              </div>
            </div>
            <div class="card-section">
              <h3>
                Data + APM
              </h3>
              <hr style="">
              <div class="cards horizontal layout wrap">
                  <krum-card-image style="width: 200px;" name="Kubecost" imageSrc="/images/partner/partner-kubecost.png"></krum-card-image>
                  <krum-card-image style="width: 200px;" name="Elasticsearch" imageSrc="/images/partner/partner-elasticsearch.png"></krum-card-image>
              </div>
            </div>
            <div class="card-section">
              <h3>
                Security + Compliance
              </h3>
              <hr style="">
              <div class="cards horizontal layout wrap">
                  <krum-card-image style="height: 72px;" name="Velocity by Stern Security" imageSrc="/images/partner/partner-velocity.png"></krum-card-image>
                  <krum-card-image style="width: 220px; height: 72px;" name="Neuvector" imageSrc="/images/partner/partner-suse-neuvector.png"></krum-card-image>
                  <krum-card-image style="width: 220px; height: 72px;" name="Sonatype" imageSrc="/images/partner/partner-sonatype.png"></krum-card-image>
              </div>
            </div>
            <div class="card-section">
              <h3>
                Monitoring and Observability
              </h3>
              <hr style="">
              <div class="cards horizontal layout wrap">
                  <krum-card-image style="width: 237px;" name="StackState" imageSrc="/images/partner/partner-stackstate.png"></krum-card-image>
              </div>
            </div>
            <div class="card-section">
              <h3>
                E-commerce + Money Management
              </h3>
              <hr style="">
              <div class="cards horizontal layout wrap">
                  <krum-card-image name="Stripe" imageSrc="/images/partner/partner-stripe.png"></krum-card-image>
              </div>
            </div>
        </section>
    `;
    }
}

customElements.define('krum-section-partnersolutions', KrumSectionPartnerSolutions);
